<template>

    <div>
        <iframe :id="domId" class="ifr-content" width="100%" frameborder="0" style="overflow-y: hidden;"></iframe>
    </div>

</template>

<script>

import $ from 'jquery'

export default {
    name: "htmlOpen",
    props: [
        'htmlCode',
        'targetId',
    ],
    data() {

        let domId = "ifr-content";
        if (this.targetId !== undefined) {
            domId = this.targetId
        }

        return {
            domId,
        }
    },
    created() {

    },
    mounted() {

        this.init()
        // alert( doc.body.scrollHeight );

        // resize on window resize
        window.onload = () => this.frameResize();
        window.onresize = () => this.frameResize();

        /*var cssLink = document.createElement("link");
        cssLink.href = "/assets/css/app.min.css";
        cssLink.rel = "stylesheet";
        cssLink.type = "text/css";
        $frame[0].contentWindow.document.head.appendChild( cssLink )*/

    },
    updated() {

        this.frameResize();
    },
    methods: {

        async init() {

            await this.setHtml();

            setTimeout(() => {
                this.$nextTick(() => {
                    // iFrameResize({ log: true }, '#ifr-content')
                    this.frameResize();
                });
            }, 500)

        },

        setHtml() {

            return new Promise((resolve) => {

                var $frame = $('#' + this.domId)
                var doc = $frame[0].contentWindow.document;

                var $body = $('body', doc);

                // var cssLink1 = document.createElement("link");
                // cssLink1.href = "/assets/css/app.min.css";
                // cssLink1.rel = "stylesheet";
                // cssLink1.type = "text/css";
                // $frame[0].contentWindow.document.head.appendChild(cssLink1)

                /*var cssLink2 = document.createElement("link");
                cssLink2.href = "/assets/css/style.css";
                cssLink2.rel = "stylesheet";
                cssLink2.type = "text/css";
                $frame[0].contentWindow.document.head.appendChild( cssLink2 )*/

                $body.html(this.htmlCode);

                doc.body.style.margin = 0;

                resolve();

            })

        },

        frameResize() {

            // alert( "frameResize" );
            //find the height of the internal page
            var the_height = document.getElementById(this.domId).contentWindow.document.body.scrollHeight + 20;

            //change the height of the iframe
            document.getElementById(this.domId).height = the_height;

            //document.getElementById('the_iframe').scrolling = "no";
            document.getElementById(this.domId).style.overflowY = "hidden";


        },

    },
    watch: {

        'htmlCode': function (newVal, oldVal) {
            // console.log(oldVal)
            // console.log(newVal)
            if (newVal !== undefined) {
                this.init()
                this.frameResize()
            }
        }

    }
}
</script>

<style scoped>
.ifr-content {
    display: block;
    border: none;
    /*height: 100vh;*/
    /*width: 100vw;*/
}
</style>